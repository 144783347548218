@import "./assets/scss/custom.scss";
@font-face {
  font-family: 'FRM-Aber Mono';
  src: url('../public/assets/FRM-AberMono-Regular.eot');
  src: url('../public/assets/FRM-AberMono-Regular.eot?#iefix') format('embedded-opentype'),
      url('../public/assets/FRM-AberMono-Regular.woff2') format('woff2'),
      url('../public/assets/FRM-AberMono-Regular.woff') format('woff'),
      url('../public/assets/FRM-AberMono-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
body {
  margin: 0;
  font-family:"FRM-Aber Mono", monospace ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.rubik{
  font-family: "FRM-Aber Mono";
  font-optical-sizing: auto;
  font-weight: weight;
  font-style: normal;
}
code {
  font-family: "FRM-Aber Mono", monospace ;
}


