html,
body {
  margin: 0;
  line-height: 1 !important;
  font-family: "FRM-Aber Mono" !important;
  overflow-x: hidden;
background-image: url('../images/analyticsBackground.svg');
  width: 100%;
 background-size: cover;
  background-repeat: no-repeat;
  background-color: #0B0B0B!important;
}
.cursor-pointer {
  cursor: pointer;
}

@media (max-width: 800px) {
  .dashboard-card{
    background-image: url("../images/card.svg");
    background-repeat: no-repeat;
  
  }
  .mobile-responsive{
    display: grid!important;
  }
}

.mobile-responsive{
  display: flex;
}

.header-button{
  border-radius: 50px;
  border: 1px solid #D9B373;
  padding: 10px 20px 10px 20px;
  background-color: #1A1D1F;
}
.header-button-transaction{
  border-radius: 50px;
  padding: 10px 20px 10px 20px;
  background-color: #1A1D1F;
}
.header-button-multiswap{
  border-radius: 50px;
  padding: 10px 20px 10px 20px;
  background-color: #D9B373;
}
.dashboard-card{
  background-image: url("../images/card.svg");
  background-repeat: round;

}
.graph-card{
  background-image: url("../images/graphCard.png");
  background-repeat: round;
}